export const initLayerData = [
  {
    id: 0,
    title: "Background",
    images: [],
    rarity: 100,
  },
  {
    id: 1,
    title: "Eye",
    images: [],
    rarity: 100,
  },
  {
    id: 2,
    title: "Head",
    images: [],
    rarity: 100,
  },
];
